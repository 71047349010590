@import '../../styles/helpers/index.scss';

.makes-and-models {
  margin: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &__nav {
    @include align-center;
    justify-content: space-between;

    button {
      position: relative;
      border: 1px solid map-get($colors, 'blue');
      background-color: #fff;
      width: 50px;
      height: 50px;

      cursor: pointer;

      &::before {
        content: '\f105';
        font-family: 'fontello';
        color: #1e96e1;
        font-size: 20px;
        line-height: 1;
        opacity: 0.75;
      }

      &:disabled {
        background-color: #eee;
        border-color: #eee;
        cursor: not-allowed;
        opacity: 0.7;
      }

      &.prev::before {
        content: '\f104';
      }
    }
  }

  &__slider {
    @include grid($columns: repeat(5, 1fr), $rows: repeat(5, 1fr), $row-gap: 1px, $column-gap: 1px);
    background-color: rgba(42, 106, 209, 0.16);

    @media screen and (max-width: $tablet-sm) {
      grid-template-columns: repeat(3, 1fr);
    }

    li {
      position: relative;
      padding: 15px;
      background: #fff;

      span {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }

      img {
        margin: 0 auto;
        height: auto;
        width: 70%;
        object-fit: cover;
      }
    }
  }

  &__list {
    margin: 60px 0 0;
    columns: 3;
    padding: 0 30px;

    @media screen and (max-width: $tablet-sm) {
      columns: 1;
    }

    li {
      position: relative;
      display: flex;
      align-items: center;
      font-family: 'Open Sans', sans-serif;

      @media screen and (max-width: $tablet-sm) {
        font-size: 12px;
      }

      &::before {
        content: '\f105';
        font-family: 'fontello';
        color: #d1373d;
        margin-right: 15px;
        font-weight: bold;
      }

      &:not(:last-of-type) {
        margin: 0 0 15px;
      }
    }
  }

  .slick-slider {
    .slick-next,
    .slick-prev {
      width: 60px;
      height: 60px;
      background: #fff;
      border: 1px solid map-get($colors, 'blue');
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotate(90deg);
      cursor: pointer;
      &::before {
        font-family: 'fontello';
        color: map-get($colors, 'blue');
      }
      @media screen and (max-width: $tablet-sm) {
        top: -30px;
        width: 45px;
        height: 45px;
      }
    }
    .slick-next {
      top: 120%;

      @media screen and (max-width: $tablet-lg) and (orientation: landscape) {
        top: 100%;
      }

      &::before {
        content: '\f105';
      }
      // @media screen and (max-width: $tablet-sm) {
      //   right: 0;
      // }
    }
    .slick-prev {
      top: -30%;
      &::before {
        content: '\f104';
      }
      // @media screen and (max-width: $tablet-sm) {
      //   right: auto;
      //   left: 0;
      // }
    }
  }
}
