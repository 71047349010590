@import '../../styles/helpers/index.scss';

.hero {
  background-color: #115a85;
  display: flex;

  @media screen and (max-width: $tablet-sm) {
    flex-direction: column;
  }

  & > div {
    max-width: 50%;
    width: 100%;

    @media screen and (max-width: $tablet-sm) {
      max-width: 100%;
    }
  }

  &__content {
    position: relative;
    padding: 90px 0 55px 100px;
    color: #fff;

    overflow: hidden;

    @media screen and (max-width: $tablet-sm) {
      padding: 0 15px 30px;
    }

    @media screen and (max-width: $tablet-lg) and (orientation: landscape) {
      padding: 90px 15px 100px;
    }

    &::before {
      content: '';
      background-image: url('../../static/images/hero-pseudo.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      height: 300px;
      width: 415px;

      position: absolute;
      right: -130px;
      bottom: -50px;

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }

    &--mobile_img {
      display: none;
      margin: 0 -15px;

      height: 250px;

      img {
        object-fit: cover;
        height: inherit;
        width: 100%;
      }

      @media screen and (max-width: $tablet-sm) {
        display: block;
      }
    }

    h1 {
      font-weight: 600;
      font-size: 50px;
      line-height: 116%;
      margin: 0 0 25px;

      @media screen and (max-width: $tablet-sm) {
        margin: 25px 0;
        font-size: 46px;
        line-height: 116%;
      }

      .mobile-br {
        @media screen and (min-width: 1600px) {
          display: block;
        }
      }
    }

    ul {
      list-style-type: disc;
      padding: 0 0 0 30px;
      margin: 0 0 30px;
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }

    span {
      display: block;
      margin: 30px 0 0;
      font-weight: 600;
      font-size: 24px;
      line-height: 200%;

      @media screen and (max-width: $tablet-sm) {
        font-size: 18px;
        line-height: 200%;
        margin: 15px 0 0;
      }

      a {
        color: #fff;
        text-decoration: underline !important;
      }
    }
  }

  &__banners {
    height: inherit;
    background-image: url('../../static/images/hero-right-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0 60px 40px;

    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    position: relative;
    z-index: 1;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.1);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    @media screen and (max-width: $tablet-sm) {
      background: none;
      padding: 0 15px 20px;
    }

    @media screen and (max-width: $tablet-lg) and (orientation: landscape) {
      padding: 0 20px 40px;
    }

    &--awards {
      @include space-between;
      align-items: center;
      margin: 0 0 20px;

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }

      &__item {
        @include scale;

        @media screen and (max-width: $tablet-lg) and (orientation: landscape) {
          height: 60px;
        }

        img {
          @media screen and (max-width: $tablet-lg) and (orientation: landscape) {
            height: inherit;
          }
        }
      }
    }

    &--rating {
      @include grid(20px, 20px, none, repeat(2, 1fr));
      width: 100%;
      &__card {
        @include scale;
        @include space-around;
        align-items: center;
        height: 80px;
        background: rgba(#fff, 0.7);

        &--logo {
          @media screen and (max-width: $tablet-lg) and (orientation: landscape) {
            max-height: 30px;
            height: 100%;
            img {
              height: inherit;
            }
          }
        }

        &--title {
          color: map-get($colors, 'text');
          text-align: center;
          span {
            font-weight: 600;
            font-size: 18px;
            line-height: 110%;
            @media screen and (max-width: $tablet-lg) and (orientation: landscape) {
              font-size: 14px;
            }
          }
        }

        &--stars {
          margin: 10px 0 0;
          color: map-get($colors, 'red');
          white-space: nowrap;
          i {
            &:not(:last-of-type) {
              margin: 0 10px 0 0;
              @media screen and (max-width: $tablet-lg) and (orientation: landscape) {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
